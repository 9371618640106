import { useToast, useDisclosure } from '@chakra-ui/react'
import React, { useState, useEffect, useRef } from 'react'
import {
  DOPPEL_BUTTON_GREY,
  DOPPEL_TERMINAL_BLUE,
  DOPPEL_WHITE,
} from '../../utils/style'
import {
  useGetTagsQuery,
  useUpdateSpoofingReportsMutation,
} from '../../generated/graphql'
import { useIsEmployeeView, useOrgID } from '../../hooks/id_token_claims'
import BulkMultiActionButton from './bulk_multi_action_button'
import DoppelAlertDialog from '../shared/doppel_alert_dialog'
import { delayRefetchedQueries } from '@/utils'
import { withApollo } from '@apollo/react-hoc'
import { ReportChangeEventSource, TagActionType } from '@/generated/enums'
import { getTagDisplayName } from '../../utils/tags'

function BulkTagButton({ selectedRows, isOrgUnifiedView, client }) {
  const [updateSpoofingReportsMutation] = useUpdateSpoofingReportsMutation()
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredTags, setFilteredTags] = useState([])
  const [tags, setTags] = useState([])
  const orgId = useOrgID()
  const [isEmployeeView] = useIsEmployeeView()

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  const toast = useToast()

  const [isDialogOpen, setDialogOpen] = useState(false)
  const [isMenuOpen, setMenuOpen] = useState(false)

  const handleMenuClose = () => {
    setMenuOpen(false)
    setDialogOpen(true)
  }

  const handleMenuOpen = () => {
    setMenuOpen(true)
  }

  const handleCloseDialog = () => {
    setTags([])
    setDialogOpen(false)
  }

  const { data: tagsData, loading: tagsLoading } = useGetTagsQuery({
    variables: {
      orgIDs: isOrgUnifiedView ? [] : [orgId],
      includeGlobal: true,
    },
  })

  useEffect(() => {
    if (!tagsLoading && tagsData) {
      const filtered = tagsData.tags.filter((tag) =>
        tag.name.toLowerCase().includes(searchQuery.toLowerCase()),
      )
      setFilteredTags(filtered)
    }
  }, [tagsData, searchQuery])

  const handleSearchChange = (event) => setSearchQuery(event.target.value)

  const confirmTags = () => {
    const confirmedTags = []

    const tagPromises = tags.map((tag) =>
      updateSpoofingReportsMutation({
        variables: {
          input: {
            report_ids: selectedRows.map((row) => row.original.id),
            update_source: ReportChangeEventSource.BULK_UI,
            tag_id: tag.id,
            tag_action: TagActionType.ADD,
          },
        },
      })
        .then(() => {
          confirmedTags.push(tag)
        })
        .catch((e) => {
          toast({
            title: `Error adding tags`,
            description: `Error: ${e.message}`,
            status: 'error',
            isClosable: true,
          })
        }),
    )

    Promise.all([...tagPromises]).finally(() => {
      if (confirmedTags) {
        const tagNames = confirmedTags.map((tag) => tag.name).join(', ')
        toast({
          title: `Success!`,
          description: `Added tags: ${tagNames}`,
          status: 'success',
          isClosable: true,
        })

        setTimeout(() => {
          delayRefetchedQueries(client, ['SearchSpoofingReports'])
        }, 3000)
        setTags([])
      }
    })

    setDialogOpen(false)
    onClose()
  }

  const tagMessage = `Are you sure you want to add the selected tags (${tags
    .map((tag) => tag.name)
    .join(', ')}) to ${selectedRows.length} reports?`

  const menuListItems = [
    ...filteredTags.filter((tag) => isEmployeeView || !tag.is_internal),
  ]

  return (
    <>
      <BulkMultiActionButton
        _hover={DOPPEL_BUTTON_GREY}
        bgColor={DOPPEL_BUTTON_GREY}
        getDisplayName={getTagDisplayName}
        handleMenuClose={handleMenuClose}
        handleMenuOpen={handleMenuOpen}
        handleSearchChange={handleSearchChange}
        isLoading={false}
        isMenuOpen={isMenuOpen}
        menuListItems={menuListItems}
        onSelectionChange={setTags}
        textColor={DOPPEL_WHITE}
      >
        {'Bulk Tag'}
      </BulkMultiActionButton>

      <DoppelAlertDialog
        bgColor={DOPPEL_TERMINAL_BLUE}
        body={tagMessage}
        cancelRef={cancelRef}
        confirmAction={confirmTags}
        header="Tag Update"
        isOpen={isDialogOpen && tags.length > 0}
        onClose={handleCloseDialog}
      />
    </>
  )
}

export default withApollo<any>(BulkTagButton)
