import React from 'react'
import { Image, Flex, Tooltip } from '@chakra-ui/react'

const COUNTRY_CODE_SIZE = '12px'

const CountryCode = ({ countryCode, tooltip }) => {
  const flagCode = countryCode.toLowerCase()
  const flagSrc = `https://flagcdn.com/48x36/${flagCode}.png`

  return (
    <Flex alignItems="center" gap="4px">
      <Tooltip label={tooltip}>
        <Image boxSize={COUNTRY_CODE_SIZE} src={flagSrc} />
      </Tooltip>
    </Flex>
  )
}

export default CountryCode
