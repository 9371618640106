import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Tag,
} from '@chakra-ui/react'
import { CLASSIFICATION_TO_LABEL, Classification } from '../../utils/constants'
import { DOPPEL_INTERNAL_PURPLE, DOPPEL_WHITE } from '../../utils/style'
import { getColorForSpoofStatus } from '../web2/spoof_status_tag_with_menu'

export default function BulkClassifyButton({
  selectedRows,
  loading,
  updateReportFunc,
}) {
  return (
    <Menu>
      <MenuButton
        as={Button}
        bgColor={DOPPEL_INTERNAL_PURPLE}
        disabled={loading}
        fontSize="11"
        h="30px"
        minWidth="80px"
        textColor={DOPPEL_WHITE}
      >
        {loading ? <Spinner size="xs" /> : 'Bulk Classify'}
      </MenuButton>

      <MenuList>
        {Object.values(Classification)
          .filter((statusOption) =>
            [
              Classification.ACTIVE,
              Classification.PARKED,
              Classification.DOWN,
            ].includes(statusOption),
          )
          .map((statusOption) => (
            <MenuItem
              key={'bulk_classify_' + statusOption}
              onClick={() => {
                updateReportFunc(selectedRows, { classification: statusOption })
              }}
              value={statusOption}
            >
              <Tag
                bgColor={getColorForSpoofStatus(statusOption)}
                color={'#FFFFFF'}
                fontSize={12}
                width="fit-content"
              >
                {CLASSIFICATION_TO_LABEL[statusOption]}
              </Tag>
            </MenuItem>
          ))}
      </MenuList>
    </Menu>
  )
}
