import { Button, Menu, MenuButton } from '@chakra-ui/react'
import { DOPPEL_INTERNAL_PURPLE, DOPPEL_WHITE } from '../../utils/style'

const BulkCopyButton = ({ selectedRows }) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        bgColor={DOPPEL_INTERNAL_PURPLE}
        fontSize="11"
        h="30px"
        minWidth="80px"
        onClick={() => {
          const urls = selectedRows.map((row) => row.original.flagged_url)
          navigator.clipboard.writeText(urls.join('\n'))
        }}
        textColor={DOPPEL_WHITE}
      >
        Bulk Copy
      </MenuButton>
    </Menu>
  )
}

export default BulkCopyButton
