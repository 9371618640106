import React, { useState, useRef } from 'react'
import { Textarea, useDisclosure } from '@chakra-ui/react'
import DoppelPrimaryButton from '../shared/doppel_primary_button'
import DoppelAlertDialog from '../shared/doppel_alert_dialog'
import { DOPPEL_TERMINAL_BLUE } from '@/utils/style'

const BulkNotesButton = ({ selectedRows, updateReportFunc }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [notes, setNotes] = useState('')
  const cancelRef = useRef()

  const noteSubmission = (
    <Textarea
      minHeight="100px"
      onChange={(e) => setNotes(e.target.value)}
      placeholder={'Add notes here...'}
      value={notes}
    />
  )

  return (
    <>
      <DoppelPrimaryButton fontSize="11" h="30px" onClick={onOpen}>
        Bulk Notes
      </DoppelPrimaryButton>

      <DoppelAlertDialog
        bgColor={DOPPEL_TERMINAL_BLUE}
        body={noteSubmission}
        cancelRef={cancelRef}
        confirmAction={() => {
          updateReportFunc(selectedRows, { notes: notes })
          setNotes('')
          onClose()
        }}
        header="Bulk Notes"
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}

export default BulkNotesButton
