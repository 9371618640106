import {
  Button,
  Input,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react'
import { DOPPEL_TERMINAL_BLUE, DOPPEL_WHITE } from '../../utils/style'
import React from 'react'

const BulkMultiActionButton = ({
  children,
  menuListItems = [],
  isMenuOpen,
  handleMenuOpen,
  handleMenuClose,
  onSelectionChange,
  handleSearchChange,
  getDisplayName,
  ...props
}) => {
  return (
    <Menu
      closeOnSelect={false}
      isOpen={isMenuOpen}
      onClose={handleMenuClose}
      onOpen={handleMenuOpen}
    >
      <MenuButton
        as={Button}
        bgColor={DOPPEL_TERMINAL_BLUE}
        fontSize="11"
        h="30px"
        marginRight={2}
        minWidth="80px"
        textColor={DOPPEL_WHITE}
        {...props}
      >
        {children}
      </MenuButton>

      <MenuList maxH="300px" overflowY="auto">
        <MenuOptionGroup
          onChange={(values) => onSelectionChange(values)}
          type="checkbox"
        >
          {handleSearchChange && (
            <Input
              m={2}
              onChange={handleSearchChange}
              placeholder="Search tags..."
              variant="filled"
              width="90%"
            />
          )}

          {menuListItems.map((item, index) => (
            <MenuItemOption key={item.key || index} value={item}>
              {getDisplayName(item)}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}

export default BulkMultiActionButton
