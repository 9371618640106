import {
  MenuItem,
  Spinner,
  Input,
  useToast,
  useDisclosure,
  Text,
} from '@chakra-ui/react'
import React, { useState, useEffect, useRef } from 'react'
import { useBulkUpdateTypeForReportsMutation } from '../../generated/graphql'
import { DOPPEL_INTERNAL_PURPLE } from '../../utils/style'
import { useGetAllPlatformsQuery } from '../../generated/graphql'
import { useSelectedReportType } from '../../pages/reports'
import { getProductsByReportType } from '../../utils/reports/report_utils'
import BulkActionButton from './bulk_action_button'
import DoppelAlertDialog from '../shared/doppel_alert_dialog'

export default function BulkReroutePlatformButton({ selectedRows }) {
  const [bulkUpdateTypeForReportsMutation, { data, loading, error }] =
    useBulkUpdateTypeForReportsMutation()
  const [selectedReportType, setSelectedReportType] = useSelectedReportType()
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredPlatforms, setFilteredPlatforms] = useState([])
  const [platformToReroute, setPlatformToReroute] = useState('')

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  useEffect(() => {
    if (data) {
      toast({
        title: 'Success!',
        status: 'success',
        isClosable: true,
      })
    } else if (error) {
      toast({
        title: 'Error',
        status: 'error',
        isClosable: true,
      })
    }
  }, [data, error])

  const toast = useToast()
  const products = getProductsByReportType(selectedReportType)
  const { data: platformsData, loading: platformsLoading } = useGetAllPlatformsQuery({
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    if (!platformsLoading && platformsData) {
      const filtered = platformsData.platforms.filter((platform) =>
        platform.name.toLowerCase().includes(searchQuery.toLowerCase()),
      )
      setFilteredPlatforms(filtered)
    }
  }, [platformsData, searchQuery])

  const handleSearchChange = (event) => setSearchQuery(event.target.value)
  const handlePlatformSelect = (platform) => {
    setPlatformToReroute(platform.name)
    onOpen()
  }

  const confirmReroute = () => {
    if (platformToReroute) {
      bulkUpdateTypeForReportsMutation({
        variables: {
          ids: selectedRows.map((row) => row.original.id),
          type: platformToReroute,
        },
      })
      onClose()
    }
  }

  const menuListItems = [
    {
      key: 'searchInput',
      content: (
        <Input
          m={2}
          onChange={handleSearchChange}
          placeholder="Search platforms..."
          variant="filled"
          width="90%"
        />
      ),
    },
    ...filteredPlatforms.map((platform) => ({
      key: platform.name,
      content: (
        <MenuItem onClick={() => handlePlatformSelect(platform)}>
          {platform.name}
        </MenuItem>
      ),
    })),
  ]

  return (
    <>
      <BulkActionButton
        bgColor={DOPPEL_INTERNAL_PURPLE}
        isLoading={loading}
        menuListItems={menuListItems}
      >
        {loading ? <Spinner size="xs" /> : 'Bulk Reroute Platform (Old)'}
      </BulkActionButton>

      <DoppelAlertDialog
        body={
          <>
            <Text fontSize={'sm'} fontWeight="bold">
              [Old reroute platform logic-- will be removed from the app.]
            </Text>

            <Text mt={2}>
              Are you sure you want to reroute the selected reports to{' '}
              {platformToReroute}?
            </Text>

            <Text mt={1}>
              Note that this will likely have no visible effect, unless you are
              following the instructions from the new Bulk Reroute Platform button.
            </Text>
          </>
        }
        cancelRef={cancelRef}
        confirmAction={confirmReroute}
        header="Bulk Reroute Platform (Old)"
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}
