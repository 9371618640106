import { withApollo } from '@apollo/react-hoc'
import { VStack, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'

import {
  useSendSlackMessageMutation,
  useUpdateSpoofingReportsMutation,
} from '../../generated/graphql'
import {
  getOrgData,
  useEmail,
  useIsEmployee,
  useIsEmployeeView,
  useOrgID,
  useUserID,
} from '../../hooks/id_token_claims'
import {
  useNextSelectedSpoofReport,
  useSelectedPage,
  useSelectedPageSize,
  useSelectedSpoofReport,
} from '../../hooks/spoof_report_table_filters'
import {
  useSelectedReportStatus,
  useSelectedReportType,
  useShouldDisableArrowNavigation,
} from '../../pages/reports'
import { delayRefetchedQueries } from '../../utils'
import { ReportType, KeyCode } from '../../utils/constants'
import { createTable } from '../../utils/table_utils'
import PaginatedTable from '../tables/paginated_table'
import SpoofReportsTableActions from './spoof_reports_table_actions'
import { sendCustomerReportedBulkSlackMessage } from '../../hooks/slack_notifications'
import { ReportChangeEventSource } from '@/generated/enums'
import { useAtom } from 'jotai'
import { reportPlatformTabAtom } from '@/hooks/dark_web_platforms'
import {
  getDarkWebColumns,
  getSpoofingReportColumns,
  getSuspiciousEmailColumns,
} from './spoof_reports_table_content_columns'

const SpoofReportsTableContent = ({
  data,
  totalPageCount,
  loading,
  selectedRow,
  setSelectedRow,
  setToRefreshCache,
  showPreviews,
  client,
  isOrgUnifiedView,
}) => {
  const orgId = useOrgID()
  const [orgData] = getOrgData()
  const [email] = useEmail()
  const [userId] = useUserID()
  const [isEmployeeView] = useIsEmployeeView()
  const isEmployee = useIsEmployee()
  const [selectedSpoofReport, setSelectedSpoofReport] = useSelectedSpoofReport()
  const [selectedReportType] = useSelectedReportType()
  const [shouldDisableArrowNavigation] = useShouldDisableArrowNavigation()
  const [, setNextSelectedSpoofReport] = useNextSelectedSpoofReport()
  const [selectedPage, setSelectedPage] = useSelectedPage()
  const [selectedPageSize, setSelectedPageSize] = useSelectedPageSize()
  const [actionLoadingState, setActionLoadingState] = useState(null)
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false)
  const [isLoadingPrevPage, setIsLoadingPrevPage] = useState(false)
  const [sendSlackMessageMutation] = useSendSlackMessageMutation()
  const [selectedReportPlatform, setSelectedReportPlatform] =
    useAtom(reportPlatformTabAtom)

  const [selectedReportStatus] = useSelectedReportStatus()

  const [updateSpoofingReportsMutation] = useUpdateSpoofingReportsMutation()

  const toast = useToast()

  // wrapper for bulk calling Update Report service, refreshes frontend
  const bulkUpdateReports = (
    rows,
    { status, classification, platformSubtype, notes },
  ) => {
    setActionLoadingState({ status, classification, platformSubtype })
    if (status && !isEmployee) {
      sendCustomerReportedBulkSlackMessage(
        sendSlackMessageMutation,
        rows,
        email,
        orgData?.name,
        status,
      )
    }
    updateSpoofingReportsMutation({
      variables: {
        input: {
          report_ids: rows.map((row) => row.original.id),
          update_source: ReportChangeEventSource.BULK_UI,
          report_status: status,
          report_classification: classification,
          platform_subtype_id: platformSubtype?.id,
          notes,
        },
      },
      update: (cache, { data: { update_reports_action } }) => {
        if (update_reports_action.updated_fields) {
          const fields = Object.fromEntries(
            Object.entries(update_reports_action.updated_fields).map(([key, value]) => {
              return [key, () => value]
            }),
          )
          for (const row of rows) {
            cache.modify({
              id: cache.identify(row.original),
              fields: fields,
            })
            if (platformSubtype) {
              cache.modify({
                id: cache.identify(row.original),
                fields: {
                  platform_subtype: () => platformSubtype,
                },
              })
            }
          }
        }
      },
    })
      .then(() => {
        setSelectedSpoofReport(null)
        setActionLoadingState(null)
        toast({
          title: `Success!`,
          description: `Updated ${rows.length} reports`,
          status: 'success',
          isClosable: true,
        })
      })
      .catch((e) => {
        toast({
          title: `Error updating reports`,
          description: `Error: ${e.message}`,
          status: 'error',
          isClosable: true,
        })
      })
      .finally(() => {
        // refetch the counts
        const queriesToRefetch = ['SearchSpoofingReportsCount']
        if (platformSubtype) {
          queriesToRefetch.push('SearchSpoofingReports')
        }
        delayRefetchedQueries(client, queriesToRefetch)
      })
  }

  const handlePressKeyDown = (e) => {
    if (e.keyCode === KeyCode.ArrowLeft || e.keyCode === KeyCode.ArrowRight) {
      e.preventDefault()
    }
    switch (e.keyCode) {
      case KeyCode.ArrowLeft: {
        if (selectedSpoofReport && !loading) {
          const currentIndex = rows.findIndex(
            (row) => selectedSpoofReport && row.original.id == selectedSpoofReport.id,
          )
          if (currentIndex) {
            setSelectedSpoofReport(rows[currentIndex - 1].original)
          } else if (selectedPage > 1) {
            setIsLoadingPrevPage(true)
            setSelectedPage((s) => Number(s) - 1)
          }
        }
        break
      }
      case KeyCode.ArrowRight: {
        if (selectedSpoofReport && !loading) {
          const currentIndex = rows.findIndex(
            (row) => selectedSpoofReport && row.original.id == selectedSpoofReport.id,
          )
          if (rows[currentIndex + 1]) {
            setSelectedSpoofReport(rows[currentIndex + 1].original)
          } else if (selectedPage < totalPageCount) {
            setSelectedPage((s) => Number(s) + 1)
            setIsLoadingNextPage(true)
          }
        }
        break
      }
      case KeyCode.Escape: {
        if (selectedSpoofReport) {
          setSelectedSpoofReport(null)
        }
        break
      }
    }
  }

  useEffect(() => {
    if (isLoadingNextPage && !loading) {
      if (rows.length) {
        setSelectedSpoofReport(rows[0].original)
      }
      setIsLoadingNextPage(false)
    }
  }, [isLoadingNextPage && loading])

  useEffect(() => {
    if (isLoadingPrevPage && !loading) {
      setSelectedSpoofReport(rows[selectedPageSize - 1].original)
      setIsLoadingPrevPage(false)
    }
  }, [isLoadingPrevPage && loading])

  useEffect(() => {
    const currentIndex = rows.findIndex(
      (row) => selectedSpoofReport && row.original.id == selectedSpoofReport.id,
    )

    setSelectedRow(rows[currentIndex] ? rows[currentIndex].id : -1)
    setNextSelectedSpoofReport(
      currentIndex + 1 < rows.length && currentIndex >= 0
        ? rows[currentIndex + 1].original
        : null,
    )
    if (shouldDisableArrowNavigation) {
      return
    }
    document.addEventListener('keydown', handlePressKeyDown)
    return () => {
      document.removeEventListener('keydown', handlePressKeyDown)
    }
  }, [selectedSpoofReport, shouldDisableArrowNavigation])

  const columns = React.useMemo(() => {
    let allColumns = []
    switch (selectedReportType) {
      case ReportType.SUSPICIOUS_EMAILS:
        allColumns = getSuspiciousEmailColumns(showPreviews, isEmployeeView)
        break
      case ReportType.DARK_WEB:
        allColumns = getDarkWebColumns(
          selectedReportStatus,
          selectedReportPlatform,
          showPreviews,
          isEmployeeView,
        )

        break
      default:
        allColumns = getSpoofingReportColumns(
          selectedReportType,
          selectedReportStatus,
          isOrgUnifiedView,
          orgId,
          showPreviews,
          isEmployeeView,
          orgData,
          loading,
        )
    }

    // Rearranging the columns because some are shared with dark web.
    const classificationIndex = allColumns.findIndex(
      (col) => col.accessor === 'spoof_status',
    )

    // Find the index of the "Uploaded By" column
    const countryIndex = allColumns.findIndex((col) => col.id === 'country')

    if (
      classificationIndex !== -1 &&
      countryIndex !== -1 &&
      classificationIndex > countryIndex
    ) {
      // Remove the "Classification" column from its current position
      const [classificationColumn] = allColumns.splice(classificationIndex, 1)

      // Insert the "Classification" column before the "Uploaded By" column
      allColumns.splice(countryIndex, 0, classificationColumn)
    }

    return allColumns
  }, [
    selectedReportStatus,
    orgId,
    isEmployeeView,
    showPreviews,
    selectedReportPlatform,
  ])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    selectedFlatRows,
    rows,
    toggleAllRowsSelected,
    state: { pageIndex, pageSize },
  } = createTable(
    columns,
    data,
    selectedPage,
    selectedPageSize,
    totalPageCount,
    (row) => row.id,
  )

  useEffect(() => {
    if (!loading) {
      toggleAllRowsSelected(false)
    }
  }, [selectedReportStatus])

  const pageSizeOptions = isEmployeeView ? [30, 50, 100, 200, 500] : [30, 50, 100]

  return (
    <VStack>
      <SpoofReportsTableActions
        actionLoadingState={actionLoadingState}
        isOrgUnifiedView={isOrgUnifiedView}
        selectedRows={selectedFlatRows}
        updateReports={bulkUpdateReports}
      />

      <PaginatedTable
        getTableBodyProps={getTableBodyProps}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        loading={loading}
        page={page}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageSizeOptionsOverride={pageSizeOptions}
        prepareRow={prepareRow}
        selectedPage={selectedPage}
        selectedRow={selectedRow}
        setSelectedObj={setSelectedSpoofReport}
        setSelectedPage={setSelectedPage}
        setSelectedPageSize={setSelectedPageSize}
        totalPageCount={totalPageCount}
      />
    </VStack>
  )
}

export default withApollo<any>(SpoofReportsTableContent)
