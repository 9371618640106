import { useEffect, useState } from 'react'
import { useSearchSpoofingReportsCountQuery } from '../../generated/graphql'
import { useSearchKey, useSelectedAutocompleteReportId } from '../../pages/reports'
import {
  ReportStatus,
  ReportType,
  SUSPICIOUS_EMAILS_REPORT_STATUSES,
} from '../../utils/constants'
import ReportStatusTab from '../shared/report_status_tab'
import { cleanUrl } from '@/utils/domain_utils'

export default function SpoofReportTab({ reportStatus, spoofingReportsWhereClause }) {
  const [count, setCount] = useState(0)
  const [searchKey] = useSearchKey()
  const [selectedAutocompleteReportId] = useSelectedAutocompleteReportId()

  const updatedSpoofingReportsWhereClause = _updateSpoofingReportsWhereClauseWithStatus(
    spoofingReportsWhereClause,
    reportStatus,
  )

  const { data: aggregateReportSearchCount, loading: loadingSearch } =
    useSearchSpoofingReportsCountQuery({
      variables: {
        spoofingReportsWhere: updatedSpoofingReportsWhereClause,
        searchKey: cleanUrl(searchKey),
        skipNormalSearch: !selectedAutocompleteReportId && Boolean(searchKey),
        skipFullTextSearch: Boolean(selectedAutocompleteReportId) || !searchKey,
      },
    })

  useEffect(() => {
    const data =
      aggregateReportSearchCount?.spoofing_reports_aggregate ||
      aggregateReportSearchCount?.search_spoofing_reports_aggregate
    const count = data?.aggregate.count || 0

    setCount(count)
  }, [aggregateReportSearchCount])

  return (
    <ReportStatusTab
      count={count}
      isLoading={loadingSearch}
      reportStatus={reportStatus}
    />
  )
}

export function availableStatuses(
  isEmployeeView: boolean,
  reportType: ReportType,
): Array<ReportStatus> {
  if (reportType === ReportType.SUSPICIOUS_EMAILS) {
    return SUSPICIOUS_EMAILS_REPORT_STATUSES
  }
  const defaultStatuses: Array<ReportStatus> = [
    ReportStatus.ENUM_NEEDS_REVIEW,
    ReportStatus.ENUM_NEEDS_CONFIRMATION,
    ReportStatus.ENUM_REPORTED,
    ReportStatus.ENUM_RESOLVED,
    ReportStatus.ENUM_APPROVED_DERIVATIVE,
    ReportStatus.ENUM_ARCHIVED,
  ]
  if (isEmployeeView) {
    defaultStatuses.push(
      ReportStatus.ENUM_INTERNAL_REVIEW,
      ReportStatus.ENUM_INTERNAL_BAD_MATCH,
    )
  }
  if (reportType === ReportType.DARK_WEB) {
    const index: number = defaultStatuses.indexOf(ReportStatus.ENUM_RESOLVED)
    defaultStatuses.splice(index, 1)
  }
  return defaultStatuses
}

function _updateSpoofingReportsWhereClauseWithStatus(
  spoofingReportsWhereClause,
  reportStatus,
) {
  // remove the status filter if it's already there and add clause for the new status
  const updatedSpoofingReportsWhereClause = structuredClone(spoofingReportsWhereClause)
  for (let i = 0; i < updatedSpoofingReportsWhereClause['_and'].length; i++) {
    if (updatedSpoofingReportsWhereClause['_and'][i].report_status) {
      updatedSpoofingReportsWhereClause['_and'][i].report_status = { _eq: reportStatus }
    }
  }
  return updatedSpoofingReportsWhereClause
}
