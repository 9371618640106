import { Button, Menu, MenuButton } from '@chakra-ui/react'
import { DOPPEL_INTERNAL_PURPLE, DOPPEL_WHITE } from '../../utils/style'

// https://stackoverflow.com/a/74720673 to get working with Chrome
const BulkOpenButton = ({ selectedRows, selectorFunc, ...props }) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        bgColor={DOPPEL_INTERNAL_PURPLE}
        fontSize="11"
        h="30px"
        minWidth="80px"
        onClick={() => {
          const urls = selectedRows.map((row) => selectorFunc(row))
          urls.forEach((url: string) => {
            // Since reports table opens Doppel links while others open flagged URLs, need to support http for local dev
            if (url.includes('http://') || url.includes('https://')) {
              window.open(url)
            } else {
              window.open(`https://${url}`, url)
            }
          })
        }}
        textColor={DOPPEL_WHITE}
        {...props}
      >
        Bulk Open
      </MenuButton>
    </Menu>
  )
}

export default BulkOpenButton
