import { useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { useIsEmployeeView } from '../../hooks/id_token_claims'
import { usePublishRequestSpoofScreenshotMutation } from '../../generated/graphql'
import DoppelPrimaryButton from '../shared/doppel_primary_button'

export default function BulkScreenshotButton({ selectedRows }) {
  const [isEmployeeView] = useIsEmployeeView()
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  const [publishRequestSpoofScreenshotMutation] =
    usePublishRequestSpoofScreenshotMutation()

  if (!isEmployeeView || selectedRows.length === 0) {
    return null // It's more conventional to return null for "nothing to render"
  }

  const handleClick = async () => {
    setLoading(true)

    // Prepare all the promises for the mutation calls
    const promises = selectedRows.map((row) =>
      publishRequestSpoofScreenshotMutation({
        variables: { report_id: row.original.id },
      }),
    )

    try {
      // Execute all the mutation calls concurrently
      const results = await Promise.all(promises)

      // Gather details from responses for the toast
      const responseDetails = results.map((result, index) => {
        const data = result.data?.publish_request_spoof_screenshot
        const status = data?.screenshot_url_already_exists
          ? 'Screenshot Already Present'
          : data?.report_screenshot_url
          ? 'Screenshot Generated'
          : 'Did Not Screenshot Alert'

        return `Row ${index + 1}: ${status}`
      })

      // Provide feedback for all reports
      toast({
        title: 'Bulk Operation Complete',
        description: `Details:\n${responseDetails.join('\n')}`,
        status: 'success',
        isClosable: true,
        duration: 9000, // Adjust as necessary for reading comfort
      })
    } catch (error) {
      // Handle errors for any rejected promises
      toast({
        title: 'An error occurred',
        description: error.message,
        status: 'error',
        isClosable: true,
      })
    } finally {
      // Ensure loading state is updated appropriately
      setLoading(false)
    }
  }

  return (
    <DoppelPrimaryButton
      disabled={loading}
      fontSize="11"
      h="30px"
      isInternal
      isLoading={loading}
      onClick={handleClick}
    >
      Bulk Screenshot
    </DoppelPrimaryButton>
  )
}
