import { Box, HStack, Icon, Tag, Tooltip } from '@chakra-ui/react'
import { useIsEmployeeView } from '../../hooks/id_token_claims'
import {
  DomainPlatform,
  ENFORCEMENT_STATUS,
  JIRA_TICKET_STATUS,
} from '../../utils/constants'
import {
  DOPPEL_BREACH_RED_SHADE,
  DOPPEL_INTERNAL_PURPLE,
  DOPPEL_WHITE,
} from '../../utils/style'
import {
  POSITIVE_ENFORCEMENT_STATUSES,
  NEGATIVE_ENFORCEMENT_STATUSES,
} from '../enforcements/enforcement_statuses'
import { WarningIcon } from '@chakra-ui/icons'
import { useEffect, useState } from 'react'
import { useGetJiraMetadataFromEnforcementRequestIdLazyQuery } from '@/generated/graphql'

const ProgressBar = ({ posValue, negValue }) => {
  return (
    <HStack bgColor="gray.200" height="10px" spacing={0} width="100px">
      <Tooltip label={posValue}>
        <Box bgColor="green.500" height="full" width={posValue} />
      </Tooltip>

      <Tooltip label={negValue}>
        <Box bgColor="red.400" height="full" width={negValue} />
      </Tooltip>
    </HStack>
  )
}

const getCompletionPercentage = (enforcement_requests, statusList) => {
  const valSum = enforcement_requests.reduce((accumulator, request) => {
    const val = statusList.indexOf(request.status)
    // If val >= 2 we set 100% completion as it is a terminal state
    if (val >= 2) return accumulator + statusList.length
    if (val > 0) return accumulator + val + 1
    return accumulator
  }, 0)

  return Math.round((valSum * 100) / (statusList.length * enforcement_requests.length))
}

const ReportEnforcementStatus = (enforcement_requests) => {
  const [isEmployeeView] = useIsEmployeeView()

  // Map from enforcement request id to whether the ticket is active (true is active)
  const [hasActiveEnforcement, setHasActiveEnforcement] = useState(false)
  const [getJiraMetadata] = useGetJiraMetadataFromEnforcementRequestIdLazyQuery()

  useEffect(() => {
    const fetchData = async () => {
      if (!enforcement_requests.enforcement_requests) return
      const activeStatus = await enforcement_requests.enforcement_requests.reduce(
        async (overallActiveStatus, request) => {
          const response = await getJiraMetadata({
            variables: {
              enforcement_request_id: request.id,
            },
          })

          let isActive = false
          if (response.data) {
            const jiraMetadata = response.data.jira_metadata
            isActive = jiraMetadata.reduce((active, { has_platform_reply, status }) => {
              active =
                active || (has_platform_reply && status === JIRA_TICKET_STATUS.TO_DO)
              return active
            }, false)
          }
          overallActiveStatus = overallActiveStatus || isActive
          return overallActiveStatus
        },
        false,
      )

      setHasActiveEnforcement(activeStatus)
    }

    fetchData()
  }, [enforcement_requests.enforcement_requests])

  // hardcode for now since all v1 enforcements are always 50% (reported)
  if (!enforcement_requests || !enforcement_requests.enforcement_requests.length) {
    if (isEmployeeView) {
      return (
        <Tag
          bgColor={DOPPEL_INTERNAL_PURPLE}
          color={DOPPEL_WHITE}
          fontSize={12}
          width="fit-content"
        >
          No Requests
        </Tag>
      )
    } else {
      return <ProgressBar negValue="0%" posValue="50%" />
    }
  }

  // ignore bing for now
  // ignore canceled if customer view
  const enforcementRequests = enforcement_requests.enforcement_requests.filter(
    (request) =>
      request.platform !== DomainPlatform.BING &&
      (isEmployeeView || request.status !== ENFORCEMENT_STATUS.CANCELED),
  )

  const posValue =
    getCompletionPercentage(enforcementRequests, POSITIVE_ENFORCEMENT_STATUSES) + '%'
  const negValue =
    getCompletionPercentage(enforcementRequests, NEGATIVE_ENFORCEMENT_STATUSES) + '%'

  return (
    <HStack>
      <ProgressBar negValue={negValue} posValue={posValue} />

      {isEmployeeView && hasActiveEnforcement && (
        <Tooltip label={'Follow up needed'}>
          <Icon as={WarningIcon} color={DOPPEL_BREACH_RED_SHADE} fontSize={12} />
        </Tooltip>
      )}
    </HStack>
  )
}

export default ReportEnforcementStatus
