import { Tag, Wrap, WrapItem } from '@chakra-ui/react'

type TableTabGroupProps = {
  data: string[] | Object
}

const renderArrayToTags = (arr) => {
  if (!arr) return <></>

  return arr.map((entry) => {
    return (
      <WrapItem key={entry}>
        <Tag>{entry}</Tag>
      </WrapItem>
    )
  })
}

const renderObjectToTags = (obj) => {
  if (!obj) return <></>

  return Object.entries(obj).map(([key, value]) => {
    return (
      <WrapItem key={key}>
        <Tag key={key}>{`${key}: ${value}`}</Tag>
      </WrapItem>
    )
  })
}

const TagGroup = ({ data }: TableTabGroupProps) => {
  const tags = Array.isArray(data) ? renderArrayToTags(data) : renderObjectToTags(data)

  return <Wrap>{tags}</Wrap>
}

export default TagGroup
