import { Flex, VStack } from '@chakra-ui/react'
import { useScrollPosition } from '../../utils'
import BulkActionButtons from '../tables/bulk_action_buttons'

const SpoofReportsTableActions = ({
  selectedRows,
  actionLoadingState,
  updateReports,
  isOrgUnifiedView,
}) => {
  const scrollPosition = useScrollPosition()

  if (!selectedRows.length) return <></>

  return (
    <VStack align="left" spacing={3} w="100%">
      <Flex
        direction="column"
        padding={scrollPosition > 150 ? '12px' : '0px'}
        position={scrollPosition > 150 ? 'fixed' : 'initial'}
        top="0px"
        w="100%"
        zIndex={2}
      >
        <BulkActionButtons
          actionLoadingState={actionLoadingState}
          isOrgUnifiedView={isOrgUnifiedView}
          selectedRows={selectedRows}
          updateReportFunc={updateReports}
        />
      </Flex>
    </VStack>
  )
}

export default SpoofReportsTableActions
