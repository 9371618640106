import { Dataset } from '../utils/analytics'

export const sortTagsAlphabetically = (a, b) => a.tag.name.localeCompare(b.tag.name)

export const sortDatasetsByTotalValue = (datasets: Dataset[]): Dataset[] => {
  return [...datasets].sort((a, b) => {
    const sumA = a.values.reduce((sum, value) => sum + value, 0)
    const sumB = b.values.reduce((sum, value) => sum + value, 0)
    return sumB - sumA
  })
}
